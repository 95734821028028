//@author: travis

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { EventService } from "../../../services/event.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { requirement, S25EvRequirementsComponent } from "../../event-details/ev.requirements.component";

@TypeManagerDecorator("s25-ng-bulk-event-requirement")
@Component({
    selector: "s25-ng-bulk-event-requirement",
    template: ` @if (this.init) {
        <div class="c-margin-top--half">
            @if (!this.remove && this.requirements.length > 0) {
                <div>
                    @if (this.commentChooser) {
                        <label
                            class="c-margin-top--half c-margin-bottom--half c-margin-right--double ngInlineBlock ngBold"
                            >Edit Comments
                            <s25-toggle-button
                                [toggleLabel]="'updateComments'"
                                [description]="'Update Comments'"
                                (modelValueChange)="setEditable($event, 'comments')"
                                [(modelValue)]="this.showComment"
                                [falseLabel]="'No'"
                                [trueLabel]="'Yes'"
                            ></s25-toggle-button>
                        </label>
                    }
                    @if (this.quantityChooser) {
                        <label
                            class="c-margin-top--half c-margin-bottom--half c-margin-right--half ngInlineBlock ngBold"
                            >Edit Quantity
                            <s25-toggle-button
                                [toggleLabel]="'updateQuantity'"
                                [description]="'Update Quantity'"
                                (modelValueChange)="setEditable($event, 'quantity')"
                                [(modelValue)]="this.showQuantity"
                                [falseLabel]="'No'"
                                [trueLabel]="'Yes'"
                            ></s25-toggle-button>
                        </label>
                    }
                </div>
            }
            <s25-ng-ev-req
                [alwaysEditing]="true"
                [(items)]="requirements"
                [showDetails]="!this.remove"
                [showRemove]="true"
                [showComment]="this.showComment"
                [showQuantity]="this.showQuantity"
                [updateCallback]="this.updateCallback"
                [addReqButton]="false"
                (itemsChange)="onItemsChange()"
            ></s25-ng-ev-req>
            <div class="spacer">
                <s25-ng-bulk-edit-save [submitFn]="saveFn" [buttonText]="this.saveButton"></s25-ng-bulk-edit-save>
            </div>
        </div>
    }`,
    styles: `
        s25-ng-ev-req .evdd-req--hasDropdown {
            margin-top: 1.5em !important;
        }

        .spacer {
            margin-top: 2em;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkEventEditRequirementComponent implements OnInit {
    @Input() itemIds: number[];
    @Input() remove: boolean = false;

    @ViewChild(S25EvRequirementsComponent) reqChild: S25EvRequirementsComponent;

    requirements: requirement[] = [];
    saveButton: string;
    chosenReq: any;
    init = false;
    showComment = false;
    showQuantity = false;

    commentChooser = false;
    quantityChooser = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    ngOnInit(): void {
        this.saveButton = this.remove ? "Remove Event Requirement" : "Update Event Requirement";
        this.requirements = S25Util.array.forceArray(this.requirements);

        this.init = true;
    }

    setEditable(newValue: boolean, type: "quantity" | "comments") {
        if (type === "quantity") this.showQuantity = newValue;
        if (type === "comments") this.showComment = newValue;
        this.reqChild.updateView();
        this.cd && this.cd.detectChanges();
    }

    get saveFn() {
        return this.save.bind(this);
    }

    save() {
        if (!this.requirements || this.requirements.length === 0) {
            alert("Please select a requirement.");
        } else {
            let rmReqIds = this.remove
                ? this.requirements.map((req: any) => {
                      return req.itemId;
                  })
                : [];
            this.requirements.forEach((req: any) => {
                if (!this.showComment) delete req.comment;
                if (!this.showQuantity) delete req.quantity;
            });
            return EventService.updateBulkRequirements(this.itemIds, this.remove ? null : this.requirements, rmReqIds);
        }
    }

    onItemsChange() {
        this.cd.detectChanges();
        // this.req = S25Util.deepCopy(this.requirements);
    }

    updateCallback = (items: any) => {
        this.updateChosen(items);
    };

    updateChosen(items: any) {
        items = S25Util.array.forceArray(items);
        this.commentChooser = items.filter((item: any) => item.allowComment).length > 0;
        this.quantityChooser = items.filter((item: any) => item.hasQuantity).length > 0;

        if (!items.length) {
            this.chosenReq = null;
        }
        this.requirements = items;
        this.cd.detectChanges();
    }
}
