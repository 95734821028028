//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpaceService } from "../../../services/space.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-location-edit-express")
@Component({
    selector: "s25-ng-bulk-location-edit-express",
    template: `
        <span class="c-margin-top--single c-margin-right--half ngInlineBlock ngBold">Location Express Scheduling</span>
        <s25-toggle-button
            (modelValueChange)="this.onToggleChange($event)"
            [modelValue]="this.removeExpress"
            [trueLabel]="trueLabel"
            [falseLabel]="falseLabel"
            class="ngBlock c-margin-bottom--single"
        ></s25-toggle-button>

        @if (!this.removeExpress) {
            <div>
                @if (!this.createNew) {
                    <label>
                        <span class="ngBold ngBlock c-margin-bottom--quarter">Default Event Type </span>
                        <s25-ng-dropdown-search-criteria
                            [type]="'eventTypes'"
                            [(chosen)]="this.eventType"
                            (chosenChange)="addEventType($event)"
                        ></s25-ng-dropdown-search-criteria>
                    </label>
                }
                <div class="ngBold c-margin-top--half c-margin-bottom--quarter">Maximum Event Duration</div>
                <s25-ng-editable-duration [allowEmpty]="true" [alwaysEditing]="true" [(val)]="this.val">
                </s25-ng-editable-duration>
            </div>
        }
        <s25-ng-bulk-edit-save
            [submitFn]="updateFn"
            [buttonText]="this.removeExpress ? 'Disable Express Scheduling' : 'Enable Express Scheduling'"
        ></s25-ng-bulk-edit-save>
    `,
    styles: `
        .timeLabel > input {
            max-width: 150px;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkLocationEditExpressComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemIds: any = undefined;
    @Input() falseLabel: string = "Enable";
    @Input() trueLabel: string = "Disable";

    eventType: any = "";
    removeExpress: boolean = false;
    chosen: any;
    days: number = 0;
    hours: number = 0;
    minutes: number = 0;
    totalMinutes: number = 0;

    val: string = "P0DT00H00M";

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    onToggleChange(event: any) {
        this.removeExpress = event;
        this.removeExpress ? (this.eventType = "") : "";
        this.cd.detectChanges();
    }

    addEventType(e: any) {
        this.eventType = e;
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let alertArr = [];
        let inputVal: any = S25Util.ISODurationToObj(this.val);
        if (this.eventType === "" && !this.removeExpress) {
            alertArr.push(alert("Please select an event type."));
        }
        if (
            !Number.isInteger(inputVal.days) ||
            !Number.isInteger(inputVal.hours) ||
            !Number.isInteger(inputVal.minutes)
        ) {
            alertArr.push(alert("Please enter a valid integer."));
        }
        if (parseInt(inputVal.hours) > 23) {
            alertArr.push(alert("Please enter a duration hours less than or equal to 23."));
        }
        if (parseInt(inputVal.minutes) > 59) {
            alertArr.push(alert("Please enter a duration minutes less than or equal to 59."));
        }
        if (alertArr.length === 0) {
            if (this.removeExpress) {
                return SpaceService.deleteExpress(this.itemIds);
            } else {
                let evenTypeId: any = "";
                !this.removeExpress ? (evenTypeId = this.eventType.val) : "";
                this.totalMinutes = S25Util.ISODurationToMinutes(this.val);
                return SpaceService.updateExpress(this.itemIds, evenTypeId, this.totalMinutes);
            }
        }
    } //END update
}
