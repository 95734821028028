//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpaceService } from "../../../services/space.service";
import { S25Const } from "../../../util/s25-const";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { TelemetryService } from "../../../services/telemetry.service";
import { S25OptimizerEffectiveDatingUtil } from "../../s25-optimizer-effective-dating/s25.optimizer.effective.dating.util";
@TypeManagerDecorator("s25-ng-bulk-location-edit-optimizer-effective-dating")
@Component({
    selector: "s25-ng-bulk-location-edit-optimizer-effective-dating",
    template: `
        @if (this.init) {
            <div class="c-margin-top--single">
                @if (this.action === "add") {
                    <div>
                        <span class="c-margin-bottom--quarter c-margin-right--half ngBlock ngBold">Add Locations</span>
                        <s25-ng-multiselect-search-criteria
                            [(modelBean)]="this.modelBeanLoc"
                            honorMatching="false"
                            type="locations"
                        ></s25-ng-multiselect-search-criteria>
                    </div>
                }
                <span class="c-margin-bottom--quarter c-margin-right--half ngInlineBlock ngBold">Effective Date</span>
                <s25-datepicker
                    [inline]="false"
                    [(modelValue)]="this.modelValue"
                    [prefType]="'U'"
                    (modelValueChange)="onChangeDate($event)"
                ></s25-datepicker>
                <div class="c-margin-top--single">
                    <label for="changeType" class="c-margin-bottom--none">
                        <span class="ngBold ngBlock c-margin-bottom--quarter" id="changeType"> Change Type</span>
                        <select
                            class="ngListPageDropdown"
                            [(ngModel)]="this.changeType"
                            name="changeType"
                            (change)="this.onChange()"
                        >
                            @for (i of this.items; track i) {
                                <option [ngValue]="i.prop">{{ i.value }}</option>
                            }
                        </select>
                    </label>
                </div>
                <div class="c-margin-top--single">
                    <label for="changeValue">
                        <span class="ngBold ngBlock c-margin-bottom--quarter"> Change Value</span>
                        @if (this.changeType === "1" || this.changeType === "2") {
                            <span>
                                <s25-ng-multiselect-search-criteria
                                    [(modelBean)]="this.modelBean"
                                    honorMatching="true"
                                    type="locationFeatures"
                                    [customFilterValue]="customFilterValue"
                                ></s25-ng-multiselect-search-criteria>
                            </span>
                        }
                        @if (this.changeType === "4") {
                            <span>
                                <s25-partition-dropdown
                                    [(chosen)]="this.partition"
                                    (chosenChange)="addPartition($event)"
                                ></s25-partition-dropdown>
                            </span>
                        }
                        @if (this.changeType === "3" || this.changeType === "5") {
                            <span>
                                <input
                                    class="c-input"
                                    id="changeValue"
                                    name="changeValue"
                                    [(ngModel)]="this.changeValue"
                                    type="number"
                                    min="0"
                                    class="cn-form__control"
                                />
                            </span>
                        }
                    </label>
                </div>
                <div class="c-displayBlock c-padding-bottom--double">
                    <button
                        class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                        (click)="update()"
                        [disabled]="this.updating"
                    >
                        {{ this.updating ? "updating...." : this.buttonText }}
                    </button>
                    <!-- <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()"> Cancel </button> -->
                </div>
                @if (this.message) {
                    <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                        <div class="cn-alert__icon cn-icon" name="alert--info">
                            <svg class="cn-svg-icon" role="img">
                                <title>Success Alert</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                ></use>
                            </svg>
                        </div>
                        <div class="cn-alert__label">
                            <span>{{ this.message }}</span>
                        </div>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkLocationEditOptimizerEfectiveDating implements OnInit {
    @Input() itemIds: any = undefined;
    @Input() submitFn: Function; //Save function that returns a promise.
    @Input() action?: string;
    @Input() chosenModels?: any = [];
    init: boolean;
    items: any;
    changeValue: number = 0;
    changeType: any = "1"; // set to string because this.items return value is string instead of integer
    modelValue: any = { date: S25Util.date.currentDate(), showToday: true };
    modelBean: any = { showResult: true, showMatching: false };
    effectiveDate = S25Util.date.currentDate();
    partition: any = "";
    customFilterValue = "&include=s25";
    modelBeanLoc: any = { showResult: true, showMatching: false, filterFavorites: false, domainFilter: false };
    getData: Array<any> = [];
    min = 0;
    max = 100;
    message: string = "";
    buttonText = "Update Optimizer Effective Dating";
    updating: boolean;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        S25OptimizerEffectiveDatingUtil.getAllSpaceChanges().then(() => {
            this.items = S25Util.array.propertyListToArray(S25Const.spaceChangeType2Text);
            this.init = true;
            this.cd.detectChanges();
        });
    }

    onToggleChange(event: any) {
        this.cd.detectChanges();
    }

    onChange() {
        parseInt(this.changeType) === 3 || parseInt(this.changeType) === 5 ? (this.changeValue = 0) : "";
        this.message = "";
        this.cd.detectChanges();
    }

    onChangeDate(e: any) {
        this.effectiveDate = e;
        this.cd.detectChanges();
    }

    addPartition(p: any) {
        this.partition = p;
        this.changeValue = p.itemId;
    }

    async update() {
        this.updating = false;
        let data = { action: this.action, chosenModels: this.chosenModels, itemIds: this.itemIds };

        let models = await S25OptimizerEffectiveDatingUtil.updateChanges(
            data,
            this.modelBeanLoc,
            parseInt(this.changeType),
            this.changeValue,
            this.modelBean,
            this.effectiveDate,
        );

        if (models && models.length > 0) {
            TelemetryService.send("OptimizerED", "Update");
            SpaceService.insertSpaceChangeModels(models).then((resp) => {
                this.message = "Success!";
                this.updating = false;
                this.cd.detectChanges();
                S25OptimizerEffectiveDatingUtil.getAllSpaceChanges(); // refresh getDate after update
            });
        } else if (models) {
            alert("Can't add, this location has same effective date and same change type in the system already.");
        }
    }
}
