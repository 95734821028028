//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpaceService } from "../../../services/space.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-location-edit-partition")
@Component({
    selector: "s25-ng-bulk-location-edit-partition",
    template: `
        <div class="c-margin-top--half c-margin-left--half ">
            <s25-partition-dropdown
                [(chosen)]="this.partition"
                (chosenChange)="addPartition($event)"
            ></s25-partition-dropdown>
            <s25-ng-bulk-edit-save [submitFn]="updateFn" [buttonText]="'Update Partition'"></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkLocationEditPartitionComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() locationIds: any = undefined;
    partition: any = "";

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    addPartition(p: any) {
        this.partition = p;
        // this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        let partition: any = "";
        this.partition ? (partition = this.partition.val) : "";
        return SpaceService.updatePartition(this.locationIds, partition);
    }
}
