//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpaceService } from "../../../services/space.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-location-edit-fillratio")
@Component({
    selector: "s25-ng-bulk-location-edit-fillratio",
    template: `
        <div>
            <label class="c-margin-top--single" for="fillRatio">
                <span class="ngBold ngBlock c-margin-bottom--quarter">Location Fill Ratio</span
                ><input
                    type="number"
                    step="10"
                    min="{{ this.min }}"
                    max="{{ this.max }}"
                    [(ngModel)]="this.fillRatio"
                    name="fillRatio"
                    class="s25-input"
                    id="fillRatio"
                    (change)="onChange()"
                />%
            </label>
            <s25-ng-bulk-edit-save [submitFn]="updateFn" [buttonText]="'Update Fill Ratio'"></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkLocationEditFillratioComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() locationIds: any = undefined;
    fillRatio: any;
    multiplier = 100;
    min = 0;
    max = 100;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    onChange() {
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        !this.fillRatio ? (this.fillRatio = "") : "";

        if (
            this.fillRatio !== "" &&
            (this.fillRatio > this.max || this.fillRatio < this.min || !Number.isInteger(this.fillRatio))
        ) {
            alert("Please enter a valid integer between " + this.min + " and " + this.max + ".");
        } else {
            this.cd.detectChanges();
            return SpaceService.updateFillRatio(this.locationIds, this.fillRatio);
        }
    }

    cancel() {
        this.fillRatio = null;
        this.cd.detectChanges();
    }
}
